<template>
  <div>
    <b-modal v-model="modalShow" size="lg" :title="$t('project.filter')" footer-class="footerClass"
      @hidden="$emit('update:show', false)"
      no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
      scrollable
    >
      <b-alert :variant="alertError? 'danger':'success'" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="alertError? ['fas', 'triangle-exclamation'] : ['far', 'check']"/>&nbsp;&nbsp;{{ alertMsg }}
      </b-alert>
      
      <b-form-group :label="$t('dataview.field.filter')" label-for="filter">
        <FilterComponent :disabled="entity.length === 0" id="filter" :root="entity.length !== 0 ? entity[0] : null" :userId="userId" v-model="query" :schema="schema" :macros="macros" :predicate="predicate" :allowEditing="false"/>
      </b-form-group>
      
      <b-row>
        <b-col>
          <label class="mr-1">{{ $t(`dataview.field.sortby`) }}</label>
          <button :disabled="entity.length === 0" id="project_filter_sort" class="btn-action" @click="sortSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
            <b-popover
              target="project_filter_sort"
              placement="top"
              triggers="hover"
              :content="$t('button.add')">
            </b-popover>
          </button>
          <BadgeGroup v-model="sortfield" class="mb-2">
            <template v-slot:default="{ item, index }">
              <Badge @badgeRemove="removeSortField" @badgeClick="editSortField"
                :text="item" 
                variant="white" 
                :pillable="!!item.pillable" :key="index" />
              </template>
          </BadgeGroup>
        </b-col>
        <b-col>
          <label class="d-block">{{ $t(`dataview.field.direction`) }}</label>
          <b-form-select size="md" :disabled="entity.length === 0" :class="sortfield.length !== 0 ? 'mt-2' : ''" class="w-auto mb-3" v-model="sortdirection" :options="sortDirectionOptions"></b-form-select>
        </b-col>
      </b-row>
        
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
    <FieldSelectModal :show.sync="showSortSelect" :root="entity.length !== 0 ? entity[0] : null" :userId="userId" :schema="schema" :macros="macros" :field="sortfield.length !== 0 ? sortfield[0] : null" :useAgFunc="false" @success="sortSelectOk"/>
    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { projectProfileService } from '@/services';

export default {
  name: 'ProjectFilterModal',
  components: {
    FilterComponent: () => import('@/components/Filter/FilterComponent'),
    FieldSelectModal:  () => import('@/components/modal/FieldSelectModal'),
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge')
  },
  props: {
    show:         { type: Boolean, required: true },
    userId:       { type: String, default: null },
    data:         { type: Object, default: null },
    profiles:     { type: Object, default: null },
    entity:       { type: Array, default: () => ['PROJECT'] }
  },
  data() {
    return {
      alertError: false,
      alertMsg: null,
      modalShow: false,
      schema: {},
      macros: {},
      predicate: {},
      query: null,
      sortfield: [],
      sortdirection: 'incr',
      sortDirectionOptions: [{text: 'Ascending', value: 'incr'},
                             {text: 'Descending', value: 'decr'}],
      
      showSortSelect: false      
    }
  },
  created() {
  
  },
  beforeDestroy() {
  
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.resetValues();
        this.alertMsg = null;
        this.modalShow = newValue;
      }
    }
  },
  computed: {
    showError() {
      return this.alertMsg != null;
    }
  },
  methods: {
    async resetValues() {
      
      this.schema = await this.$store.dispatch('data/info', { type: 'model' }).then((value) => { 
        return value;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return [];
      });
      
      this.predicate = await this.$store.dispatch('data/info', { type: 'predicate' }).then((value) => { 
        return value;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return [];
      });
      
      this.macros = await this.$store.dispatch('data/info', { type: 'macro' }).then((value) => { 
        return value;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return [];
      });
      
      this.setValues();
    },
    setValues() {
      if (this.data !== null) {
        const dataCopy = cloneDeep(this.data);
        this.query = this.prepareQuery(dataCopy.query, true);
        this.sortfield = dataCopy.sortfield !== null ? [dataCopy.sortfield] : [];
        this.sortdirection = typeof dataCopy.sortdirection !== 'undefined' ? dataCopy.sortdirection : 'incr';
      }
      else {
        this.query = null;
        this.sortfield = [];
        this.sortdirection = 'incr';
      }
    },
    prepareQuery(query, edit) {
      if (query === null) {
        return query;
      }
      
      if (query !== null) {
        if (query.type === 'group') {
          for (var i = 0; i < query.children.length; i++) {
            const child = query.children[i];
            query.children[i] = this.prepareQuery(child, edit);
          }
        }
        else {
          // Using value in v-modal causes problems getting the values
          // back from the edtiors so changing this to value1 works better
          if (edit) {
            this.$set(query, 'value1', query.value);
          }
          else {
            this.$set(query, 'value', query.value1);
            delete query.value1;
          }
        }
      }
      return query;
    },
    validateQuery(query) {
      var result = true;
      if (query === null) {
        return result;
      }
      
      if (query.type === 'group' &&
          query.operator === '_not_' &&
          query.children.length > 1) {
        result = false;
        this.alertMsg = this.$t('dataview.error.not');    
        this.alertError = true;
      }
      
      if (query !== null && query.type === 'group') {
        for (var child of query.children) {
          result = result && this.validateQuery(child);
        }
      }
      return result;
    },
    validateSharing() {
      if (this.editingPermissions === "") {
        this.alertMsg = this.$t('dataview.error.members_editing_empty');
        this.alertError = true;
        return false;
      }
      return true;
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      this.alertError = false;
      
      if (!this.validateSharing()) {
        return;
      }
      
      // prepare the query for submit
      const query = this.prepareQuery(cloneDeep(this.query), false);
      
      // check query
      if (!this.validateQuery(this.prepareQuery(this.query, false))) {
        return;
      }
      
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.filterSubmit(query);
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.alertError = true;
        }
      });
    },
    async filterSubmit(query) {
      const filter = {
                      sortfield: this.sortfield.length !== 0 ? this.sortfield[0] : null, 
                      sortdirection: this.sortdirection,
                      entity: this.entity.length !== 0 ? this.entity[0] : null, 
                      query: query
                    };
              
      if (this.profiles) {
        const data = await this.updateProjectProfile(filter);
      }
      
      this.$emit('update:show', false);
      this.$emit('success', filter);
    },  
    async updateProjectProfile(filter) {
      this.profiles.filter = filter;
      const data = await projectProfileService.update([this.profiles],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data[0];
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return null;
      });
      return data;
    },
    dismissAlert() {
      this.alertMsg = null;
      this.alertError = false;
    },
    sortSelectorToggle() {
      this.showSortSelect = true;
    },
    editSortField() {
      this.showSortSelect = true;
    },
    removeSortField() {
      this.sortfield = [];
    },
    sortSelectOk(field) {
      this.sortfield = [field.result];
    }
  }
}
</script>
